import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Wrap Wizardry
			</title>
			<meta name={"description"} content={"Мистецтво перетворення автомобіля"} />
			<meta property={"og:title"} content={"Про нас | Wrap Wizardry"} />
			<meta property={"og:description"} content={"Мистецтво перетворення автомобіля"} />
			<meta property={"og:image"} content={"https://britesside.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://britesside.com/img/2555013.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://britesside.com/img/2555013.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://britesside.com/img/2555013.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://britesside.com/img/2555013.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://britesside.com/img/2555013.png"} />
			<meta name={"msapplication-TileImage"} content={"https://britesside.com/img/2555013.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Про нас
				</Text>
			</Box>
			<Box
				display="flex"
				width="60%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Компанія Wrap Wizardry народилася з бажання поєднати мистецтво з практичністю, перетворюючи повсякденні транспортні засоби на виразники індивідуальності та стилю. Ми починали як невелика команда автомобільних ентузіастів, які вірили, що кожен автомобіль має потенціал стати шедевром. З роками ми відточували свої навички та розширювали можливості, використовуючи передові методи та найсучасніші технології, щоб гарантувати, що кожне обклеювання є витвором мистецтва. Наше прагнення до досконалості та інновацій зробило нас лідером у галузі виробництва вінілових упаковок.
				</Text>
			</Box>
			<Box
				display="grid"
				width="100%"
				margin="80px 0px 0px 0px"
				lg-margin="40px 0px 0px 0px"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				sm-grid-template-columns="1fr"
			>
				<Image
					src="https://britesside.com/img/7.jpg"
					object-fit="cover"
					min-height="350px"
					width="100%"
					height="100%"
					grid-column="2 / span 2"
					border-radius="24px"
					sm-grid-column="1 / span 1"
				/>
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-14" background="--color-lightD1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="40%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://britesside.com/img/5.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					height="100%"
				/>
			</Box>
			<Box
				display="flex"
				width="60%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="white"
				md-padding="36px 40px 48px 40px"
				sm-padding="36px 24px 48px 24px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Наша команда експертів
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					sm-text-align="left"
				>
					У Wrap Wizardry наш найбільший актив - це наша команда. Наші фахівці, що складаються з сертифікованих професіоналів з багаторічним досвідом роботи з вініловою графікою, є основою кожного приголомшливого перетворення, за яке ми беремося. Вони володіють не лише технічними навичками, необхідними для бездоганного нанесення обгортки, але й художнім смаком, який робить кожен проект унікальним. Їх відданість якості та увага до деталей гарантує, що кожен клієнт залишає наші двері з автомобілем, який з гордістю демонструє свою індивідуальність.
				</Text>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Чому Wrap Wizardry виділяється
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					lg-margin="0px 0px 36px 0px"
					sm-text-align="left"
				>
					Інноваційні технології: Ми постійно вдосконалюємо наші методи, щоб впроваджувати найновіші вінілові технології та мистецькі тенденції.
					<br />
					<br />
					Ексклюзивні матеріали: Ми обираємо лише високоякісні, міцні вініли, які гарантують довговічне покриття та яскраві кольори.
				</Text>
				<Link
					href="/contacts"
					color="--primary"
					font="--lead"
					text-decoration-line="initial"
					hover-text-decoration-line="underline"
				>
					Дізнатися більше
				</Link>
			</Box>
		</Section>
		<Section
			padding="60px 0 120px 0"
			sm-padding="40px 0 40px 0"
			sm-min-height="auto"
			background="--color-light"
			display="flex"
			flex-direction="column"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Text
				margin="0px"
				font="--headline2"
				color="--dark"
				max-width="850px"
				width="50%"
				md-width="100%"
				md-margin="0px 0px 16px 0px"
			>
				Приєднуйтесь до Wrap Wizardry
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="50%"
				md-width="100%"
				md-margin="16px 0px 0px 0px"
				sm-margin="16px 0px 32px 0px"
			>
				Коли ви обираєте Wrap Wizardry, ви не просто отримуєте вінілову обгортку - ви створюєте особисте висловлювання. Настав час залишити свій слід у світі так, як можете зробити тільки ви. Дозвольте нам допомогти вам перетворити ваш автомобіль на полотно, що відображає ваш унікальний дух та життєву енергію.
			</Text>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});